import React from "react";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import styles from "./GalleryItem.module.scss";

type GalleryItemProps = {
  imgAlt: string;
  imgSrc: string;
  customerWebsiteUrl: string;
  customerWebsiteUrlText: string;
  customerWebsiteCreateUrl: string;
  customerWebsiteCreateUrlText: string;
};

const GalleryItem: React.FC<GalleryItemProps> = ({
  imgAlt,
  imgSrc,
  customerWebsiteUrl,
  customerWebsiteUrlText,
  customerWebsiteCreateUrl,
  customerWebsiteCreateUrlText,
}) => (
  <div className={styles.galleryItem}>
    <img className={styles.itemImage} alt={imgAlt} src={imgSrc} />
    <>
      {customerWebsiteUrl && (
        <div className={styles.galleryItemOverlay}>
          <div className={styles.galleryItemCta}>
            <PrimaryButton
              className="white"
              externalClassName={styles.galleryItemBtn}
              buttonLink={customerWebsiteUrl}
              buttonText={customerWebsiteUrlText}
              target="_blank"
            />
            <PrimaryButton
              buttonLink={customerWebsiteCreateUrl}
              buttonText={customerWebsiteCreateUrlText}
            />
          </div>
        </div>
      )}
    </>
  </div>
);

export default GalleryItem;
