import React from "react";
import { GalleryNode } from "../../../assets/Types/AcfGroupTypes/ProfessionalWebsiteGalleryP.type";
import GalleryItem from "../GalleryItem";

const GalleryList = (galleryList: GalleryNode[]) =>
  galleryList.map(
    (
      {
        customerWebsite,
        customerWebsiteUrl,
        customerWebsiteUrlText,
        customerWebsiteCreateUrl,
        customerWebsiteCreateUrlText,
      },
      idx
    ) => {
      if (!customerWebsite?.mediaItemUrl) return <></>;
      return (
        <GalleryItem
          key={`${idx}${Date.now()}`}
          imgAlt={customerWebsite.altText ? customerWebsite.altText : ""}
          imgSrc={customerWebsite.mediaItemUrl}
          customerWebsiteUrl={customerWebsiteUrl}
          customerWebsiteUrlText={customerWebsiteUrlText}
          customerWebsiteCreateUrl={customerWebsiteCreateUrl}
          customerWebsiteCreateUrlText={customerWebsiteCreateUrlText}
        />
      );
    }
  );

export default GalleryList;
