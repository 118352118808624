import React from "react";
import Slider from "react-slick"; /* LINK https://react-slick.neostack.com/docs */
import GalleryList from "../../ProfessionalWebsiteGallery/GalleryList";
import RightArrow from "../../SVGIcons/RightArrow/RightArrow";
import LeftArrow from "../../SVGIcons/LeftArrow/LeftArrow";
import { GalleryNode } from "../../../assets/Types/AcfGroupTypes/ProfessionalWebsiteGalleryP.type";

import styles from "./ProfessionalSlider.module.scss";
import "./ProfessionalSlider.scss";

interface ArrowProps {
  isPrevious?: boolean;
  className?: string;
  onClick?: () => void;
}

interface ProfessionalSliderProps {
  galleryList: GalleryNode[];
}

const ProfessionalSlider: React.FC<ProfessionalSliderProps> = ({
  galleryList,
}) => {
  const Arrow = ({ onClick, className, isPrevious = false }: ArrowProps) => (
    <button className={`${styles.arrow} ${className}`} onClick={onClick}>
      {isPrevious ? <LeftArrow /> : <RightArrow />}
    </button>
  );

  const settings = {
    arrows: true,
    slidesToScroll: 1,
    className: "full-width draggable pw-gallery-slider",
    nextArrow: <Arrow />,
    prevArrow: <Arrow isPrevious />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className={`${styles.sliderContainer} pad-bottom--large`}
      data-testid="professional-slider"
    >
      <Slider {...settings}>{GalleryList(galleryList)}</Slider>
    </div>
  );
};

export default ProfessionalSlider;
