import * as React from "react";
import { FaqsC } from "../../assets/Types/AcfGroupTypes/ContentFaqsP.type";
import BlueLinkWithArrow from "../Buttons/BlueLinkWithArrow/BlueLinkWithArrow";

import styles from "./FaqsList.module.scss";

type FaqsListType = {
  faqsList: FaqsC[];
  faqsCtaUrl?: string;
  faqsCtaCopy?: string;
};

const FaqsList: React.FC<FaqsListType> = ({
  faqsList,
  faqsCtaCopy,
  faqsCtaUrl,
}) => {
  const ctaText = faqsCtaCopy || "Read all FAQs";
  const ctaUrl = faqsCtaUrl || "/features/faqs/";

  const list =
    faqsList &&
    faqsList[0]?.faqsC?.questionsAnswer?.trim()?.length !== 0 &&
    faqsList.map((listItem, index) => {
      const paddingBottom = index !== faqsList?.length - 1 ? "48px" : "";

      return (
        <div className={styles.listItem} key={index} style={{ paddingBottom }}>
          <h3>{listItem.title}</h3>
          <div
            className={styles.faqContent}
            dangerouslySetInnerHTML={{
              __html: listItem?.faqsC?.questionsAnswer,
            }}
          />
        </div>
      );
    });
  return (
    <div>
      <div
        data-testid="Div:FaqsListContainer"
        style={{ paddingBottom: "48px" }}
      >
        {list}
      </div>
      <BlueLinkWithArrow link={ctaUrl} linkCopy={ctaText} />
    </div>
  );
};

export default FaqsList;
